:root {
    /*Margins & Paddings*/
    --padding: 1em;
    --margin: 1em;
    --vertical-padding: 1em; /* must NOT be a percentage value */
    --vertical-margin: 1em;  /* must NOT be a percentage value */
     /*Breakpoints*/
    --breakpoint-m: 1100px;
    --breakpoint-s: 800px;
    --breakpoint-xs: 550px;
    --breakpoint-menu: 1200px;
     /*General*/
    --container-max-width: 1100px;
    --fixed-header-height: 80px;
    --base-font-family: Hind, sans-serif;
    --alternative-font-family: var(--base-font-family);
}

@import "/fontmodules/Merriweather.css";
@import "/fontmodules/NotoSerif.css";
@import "/fontmodules/Roboto.css";
@import "/fontmodules/RobotoCondensed.css";
@import "/fontmodules/DroidSerif.css";
@import "/cssmodules/normalize.css";
    @import "/cssmodules/leaflet2.css";
    @import "/cssmodules/basic_styling.css";
@import "/cssmodules/flex_grid.v1.1.css";
@import "/cssmodules/slick.css";
@import "/cssmodules/slick-theme.css";
@import "/cssmodules/menu.v1.2.css";
@import "/cssmodules/magnific-popup.css";
@import "/cssmodules/animate.css";



/*----------------------------------- General --------------------------------------*/

img {
    border: 0;
    max-width: 100%;
    height: auto;
}


.flex > * > img {
    flex: 0 0 auto;
}
/* cross-browser fix, obs! gäller endast för bilder i div utan klass*/
.flex > * > img:not([class]) {
    width: 100%;
}



body {
    color:#FFFFFF;
    background: #000000;
    font-family: "RobotoCondensed", san-serif;
    /* font-weight's att välja mellan är 100,300,400,500,700,900 */
}


header {
    background: rgba(255, 255, 255, 0.0);
    z-index: 100;
    position: fixed;
    top: 0;
    width: 100%;
}


.hemisdaochdesignav  {
    background:rgba(179, 0, 0, 0.8);
    padding: 8px 20px 8px 8px;

}

.hemisdaochdesignav p, .hemisdaochdesignav a {
    font-family: "Roboto", sans-serif;
    color:#FFFFFF;
    font-size: 0.8rem;
    padding:0px;
    margin:0px;
}


/*----------------------------------- Logotype --------------------------------------*/

.logo a {
    padding: 15px 0px 15px 15px;
}/*Add width:100% för att ge egen "rad"*/


.logo img {
    height: 85px;
    max-height: 85px;
    width: auto;
}


/* ---------------------------------- Rubrikerna h1 h2 h3 h4 h5 h6 --------------------------- */

h1 {
    font-family: "Merriweather",sans-serif;
    color: #b30000;
    font-size: 2.25rem;
    font-weight: 700;
    letter-spacing: 1px;
    font-style: italic;
}

h2 {
    color: #B30000;
    font-size: 2.0rem;
    font-weight: 300;
    letter-spacing: 2px;
}

h3 {
    color: #B30000;
    font-size: 1.8rem;
    font-weight: 300;
    letter-spacing: 2px;
}

h4 {
    color: #B30000;
    font-size: 1.6rem;
    font-weight: 300;
    letter-spacing: 2px;
}

h5 {
    color: #B30000;
    font-size: 1.6rem;
    font-weight: 300;
    letter-spacing: 2px;
}

h6 {
    color: #B30000;
    font-size: 1.4rem;
    font-weight: 300;
    letter-spacing: 2px;

}

p  {
    font-family: Roboto,sans-serif;
    font-size: 1.05rem;
    font-weight: 300;
    line-height: 135%;
}

.pagecontent ul {
    line-height: 150%;
    font-family: "RobotoCondensed", sans-serif;
    font-size: 1.2em;
    font-weight: 300;
    line-height: 150%;
    padding-left:1vw;
}


/*-------------------------------------------------------------- Bildspel ------------------------------------------------------------------------*/

.slick-initialized .slick-slide{
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.slick-slider {
    margin-top: 0px;
    position: fixed;
    width:100%; 
    top:0;
    z-index: -10; /* z-index - sätter vilken "nivå" bildspelet / "vad man vill" ska hamna på */
}

.slick-slide {
    height: 100vh;
    width:100%;
    background-size: cover;
    background-position: top center ;
}

.slideshow_titel {
    font-family: 'Roboto',arial,san-serif;
    background: rgba(51, 51, 51, 0.25);
    font-weight: 100;
    font-size: 2.8em;
    position: relative;
    text-transform: uppercase;
    color: #fff;
    letter-spacing: 3px;
    text-align: center;
    margin-top: 50vh;
    margin-bottom: 0;
    z-index: -8;
    width: 100%;
    height: 60px;
    border-style: solid;
    border-width: 2px;
    border-left: 0;
    border-right: 0;
    border-color: #fff;
}

/*-------------------------------------------------------------- Topimage / Toppbild ------------------------------------------------------------------------*/
.topimage {
    margin-top: 0px;
    position: fixed;
    width:100%; 
    height:100vh;
    top:0;
    z-index: -10; 
    background-size: cover;
    background-position: top center;
}


/*-------------------------------------------------------------- Pagecontent / Innehåll sida och startsida ------------------------------------------------------------------------*/

.pagecontent {
    margin-top:57vh;
    position: relative;
    z-index: 15;
    background: rgba(0, 0, 0, 0.9);
}

.bodycol {
    margin-top:0vh;
    padding-top:0vh;
}

.bodycol img {
    margin-top:3vh;
}

.pagetitle h1 {
    margin-bottom:0vh;
    padding-bottom:0vh;
}
.pagetitle {
    margin-bottom:0vh;
    padding-bottom:0vh;
}

.pdfloop  {
    color:#487d92;
    line-height: 150%;
    font-family: "RobotoCondensed", sans-serif;
    font-size: 1.1em;
    font-weight: 300;
    line-height: 150%;
    margin-top:3vh;
}

.pdfloop a {
    color:#487d92;
}

.pdfloop svg {
    fill: #487d92;
}


.margin>* {
margin-top:0px;
}

.kontaktlankboxbild {
    min-height: 250px;
    max-height: 250px;
    position: relative;
    background-size: cover;
    background-position: center center;
}

a.kontaktlankboxbild{
    display:block;
}

/*------------------------- Logobanner ---------------------------*/

.logobanner {
    padding-top: 6vh;
    padding-bottom: 3vh;
}

/*---------- Gallery galleri galleries --------------*/

.gallerybackground {
background: rgba(0,0,0,.9);
}

.imagelist_link {
    min-height: 500px;
    width: 100%;
    position: relative;
    background-size: cover;
    background-position: center center;
}

a.imagelist_link{
    display:block;
}


/*----------------------------------- Länkarna --------------------------------------*/

a {
    text-decoration: none;
}

a:active, a:hover {
    text-decoration: underline;
}

/*----------------------------------- Meny / Menu --------------------------------------*/
.menuheadmenu {
    background-image: url("/theme/intendit/graphics/menu-bg-2.png");
    background-repeat: no-repeat;
    margin-top:37px;
}

nav {
    font-size:0.9rem;
    color:rgb(0, 0, 0);
    float:right;
    font-family: "Merriweather",sans-serif;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
    line-height: 1.2rem;
}
.menu>li {
    margin: 0 1em;
    list-style: none;
    margin-bottom: 10px;
    padding: 0px 10px 8px;
}

.menu .active,
.menu .current,
.menu .activeparent,
.menu li a:active,
.menu li:hover
{
    color:rgb(255, 255, 255);
    text-decoration: none;
}


.menu li, .menu ul, .menu.menu {
    /* margin: 0; */
    /* padding: 0; */
    /* color: inherit; */
    margin-left:40px;
    margin-top: 9px;
    background: none;
}

/* ------------------------------------------------ DropDownMenyn / Drop Down Menu -------------------------------------------- */

 .menu .dropdown ul{
    top: calc(100% + 1px);
    left: 26px;
    background: rgba(255, 255, 255, 0.9);
    border-bottom:none;
    border-width: 0px;
}


.menu .dropdown li {
    white-space: nowrap;
    background: none;
}

.menu .dropdown li a {
    min-width: 100px;
    padding: 8px 2px 5px 2px;
    margin-left: 10px;
    margin-right: 10px;
}




/*-------------------------------------------------------------- Moduler / Modul / Länkboxar TEST -----------------------

.modulbackground {
    background: #FFFFFF;
    padding-top:1vh;
    padding-bottom:1vh;
}

.moduleboxwrapper {
    position: relative;
    overflow: hidden; 
    cursor: pointer; 
    margin:0; 
    width:100%;
}

.moduleboximage {
    height: 45vh;
    position: relative;
    background-size: cover;
    background-position: center top;
}


a.moduleboximage{
    display:block;
}

.boxtitle1 {
    color: rgba(255,255,255,0.0);
    margin-top:38%;
    font-family:'Roboto', san-serif;
    font-weight: 500;
    font-size: 1.8em;
    letter-spacing: 20px;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.0);
    text-transform: uppercase;
    line-height: 1.5em;
    position:absolute; 
    height:auto;
    width:100%;
    text-align: center;
    transition: color 0.3s ease-out 0s, text-shadow 0.3s ease-out 0s, letter-spacing 0.3s ease-out 0s;
}

.moduleboxwrapper:hover .boxtitle1 {
    color: rgba(255,255,255,1.0);
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.8);
    letter-spacing: 3px;
    transition: color 0.3s ease-out 0s, text-shadow 0.3s ease-out 0s, letter-spacing 0.3s ease-out 0s;
}

----------*/




/*------------------------------------- Moduler / Modul / Länkboxar ---------------*/

.modulbackground {
    background: #FFFFFF;
    padding-top:1vh;
    padding-bottom:1vh;
}

.moduleboxwrapper {
    position: relative;
    overflow: hidden; 
    cursor: pointer; 
    margin:0; 
    width:100%;
}

.moduleboximage {
    height: 320px;
    max-width: 320px;
    position: relative;
    background-size: cover;
    background-position: center top;
    transform: scale(1.0);
    transition: transform 0.5s ease-out 0s;
}

.moduleboximage:hover {
    height: 320px;
    max-width: 320px;
    position: relative;
    background-size: cover;
    background-position: center top;
    transform: scale(0.9);
    transition: transform 0.5s ease-out 0s;
}

a.moduleboximage{
    display:block;
}

.boxtitle1 {
    
    background:rgba(179, 0, 0, 0.9);
    margin-top:42%;
    font-family: "Merriweather",sans-serif;
    font-weight: 700;
    font-size: 1.2rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    line-height: 1.5em;
    position:absolute; 
    height:auto;
    width:100%;
    color: rgb(0, 0, 0);
    text-align: center;
    transition: margin-top 0.3s ease-out 0s;
    border-radius:30px 0px 30px 0px;
    padding:10px 0px 10px 0px;
}

.moduleboxwrapper:hover .boxtitle1 {
    color: rgb(255, 255, 255);
    margin-top:40%;
    transition: margin-top 0.3s ease-out 0s, color 0.3s ease-out 0s;
}





/* --------------------------------------------------------- Kontaktformulär / Kontaktbanner ----------------------------------------- */

.boltforms-row label {
    font-weight: 300;
    margin-bottom: 0;
}

.boltforms-row input {
    width: 100%;
    color: #606060;
}

.boltforms-row textarea {
    width: 100%;
    color: #606060;
    height: 15vh;
}

.boltforms-row button {
    display: block;
    width: 100%;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.2);
    margin: 0;
    border: none;
    border-radius: 2px;
    height: auto;
    padding: 10px 20px;
    font-weight: 400;
    font-size: 1em;
    line-height: 1.1em;
}

iframe {
    max-width: 100%;
    width: 100%;
    height: 30vh;
    border: none;
    display : block;
}


/*--------------------- Kontaktbanner-texten ----------------------*/

.spaceline {
margin:0px 10px 0px 10px;
}


.kontaktbanner {
    font-family: Roboto,sans-serif;
    font-size: 1.2rem;
    font-weight: 300;
    background: rgba(179, 0, 0, 0.8);
    color: #ffffff;
    padding-top: 30px;
    padding-bottom: 10px;
}

.socialmedia {
    font-size:1.3rem;
    letter-spacing: 5px;
}

.kontaktbanner a {
    color: #ffffff;
}

.kontaktbanner h5 {
    font-family: Merriweather,sans-serif;
    font-weight: 700;
    color: #ffffff;
    font-size: 1.7em;
    margin-top: 18px;
    margin-bottom: 2vh;
}

.kontaktbanner h2 {
    padding: 0px 0 10px;
}

.kontaktbanner p {
    font-size: 1.1em;
    margin-bottom: 0px;
    line-height: 150%;
}

.kontaktbanner .boltforms-row {
    float: left;
    width: 33.3%;
    padding-right: 10px;
}

.kontaktbanner .boltforms-row:nth-child(n+4) {
    width: 100%;
}

button[type=submit], input[type=submit] {
    background: #FFFFFF;
    color: #000000;
}

button[type=submit]:hover, input[type=submit]:hover {
    background: #FFFFFF;
    color: #000000;
}

/*------------------Give map a height and it's images no max-width----------*/
    .map-canvas {
    height: 38vh;
}

.map-canvas img {
    max-width: none;
}


.markerLabels{
    overflow:visible !important;
}

.fa-map-marker{
    background: url(/extensions/local/intendit/intenditExt/web/black/map-marker.svg);
    background-size: cover;
    padding: 10px;
    background-position: center;
}

/*------------------------------------------------------------------------------------------------ 
MEDIA QUERIES ----------------------------------------------------------------------------------*/

/*Allt under M-nivå*/
@media (max-width: var(--breakpoint-m)) {
    
   #menu-icon {
    padding: 16px;
}

    h1 {
        font-family: Merriweather,sans-serif;
        color: #b30000;
        font-size: 2.0rem;}

    
    .menu .dropdown ul {
    background: rgba(0, 35, 85, 0.0);
    }
    
    .menu.show {
        margin-top: 0px;
        background: rgba(179,0,0,.8);
}
    
} /* SLUT - Allt under M-nivå*/ 

/*Allt under S-nivå*/
@media (max-width: var(--breakpoint-s)) {
    
  
}  

/*Allt under XS-nivå*/
@media (max-width: var(--breakpoint-xs)) {
    
    
}

@media screen and (orientation: landscape) and (max-width: 800px) {


}

@media screen and (orientation: landscape) and (max-width: 800px) {

    
}

/*--------------------------Make FB widget responsive------------------------*/
    .fb_iframe_widget,
    .fb_iframe_widget span,
    .fb_iframe_widget iframe[style],
    .twitter-timeline {
        width: 100% !important;
    }
